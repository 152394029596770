<template>
  <v-row>
    <v-col cols="12" md="9">
      <v-card>
        <!-- <pre>
          {{ candidate }}
        </pre> -->
        <v-row>
          <v-col cols="12" md="4">
            <v-list-item>
              <v-list-item-content>
                <v-card :elevation="0" max-height="460">
                  <v-card flat fluid>
                    <v-img
                      lazy-src="https://picsum.photos/id/11/10/6"
                      :src="
                        candidate.photo
                          ? `${apiUrl}/images/candidate/${candidate.photo}`
                          : `/avatar.png`
                      "
                      contain
                    >
                    </v-img>
                    <v-card-text>
                      <div class="text-center">
                        <v-list-item>
                          <v-list-item-content>
                            <strong
                              class="body font-weight-bold text-uppercase"
                            >
                              {{ candidate.fullName }}
                            </strong>
                            <div
                              class="
                                text-subtitle-2
                                font-weight-medium
                                text--disabled
                              "
                            >
                              {{ candidate.profession }}
                            </div>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-row justify="center" v-if="candidate.media">
                              <v-btn
                                :href="`//${candidate.media.facebook}`"
                                target="_blank"
                                icon
                              >
                                <v-icon small color="primary"
                                  >mdi-facebook</v-icon
                                >
                              </v-btn>
                              <v-btn
                                :href="`//${candidate.media.linkedin}`"
                                target="_blank"
                                icon
                              >
                                <v-icon small color="primary"
                                  >mdi-linkedin</v-icon
                                >
                              </v-btn>
                              <v-btn
                                :href="`//${candidate.media.twitter}`"
                                target="_blank"
                                icon
                              >
                                <v-icon small color="primary"
                                  >mdi-twitter</v-icon
                                >
                              </v-btn>
                            </v-row>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-card>
              </v-list-item-content>
            </v-list-item>

            <div class="px-3" style="margin-top: 0%">
              <p class="pt-3 body black--text font-weight-medium">
                {{ $t("form_candidate_tools") }}
              </p>
              <v-chip-group column>
                <v-chip
                  v-for="(skill, index) in candidate.otherSkills"
                  :key="index"
                  label
                  small
                  >{{ skill }}</v-chip
                >
              </v-chip-group>
            </div>
            <div class="px-3">
              <p class="pt-5 body black--text font-weight-medium">
                {{ $t("form_candidate_inter") }}
              </p>
              <v-chip-group column multiple>
                <v-chip
                  v-for="(area, index) in candidate.areasInterest"
                  :key="index"
                  label
                  small
                  >{{ $t(area) }}</v-chip
                >
              </v-chip-group>
            </div>
          </v-col>

          <v-col cols="12" md="8" class="px-6">
            <v-row class=" d-sm-flex py-4">
              <v-btn
                v-for="tab in tabs"
                :key="tab"
                dense
                text
                small
                class="caption text-none"
                :class="{ 'text--disabled': tab.key != selectedTab }"
                :color="tab.key === selectedTab ? 'primary' : ''"
                @click="selectedTab = tab.key"
              >
                {{ tab.text }}
                <v-icon small right>mdi-play</v-icon>
              </v-btn>
            </v-row>
            <div v-if="selectedTab === 'about'">
              <div class="mt-5 pr-5">
                <div class="body mb-5 text-uppercase black--text">
                  {{ $t("about") }}
                </div>
                <p class="body-2 font-weight-regular grey--text">
                  {{ candidate.presentation }}
                </p>
              </div>
              <v-divider class="my-6"></v-divider>
              <div
                class="
                d-flex
                flex-row
                justify-space-between
                py-4
                pr-5
                ajustaEspaco2
              "
              >
                <div class="text--secondary body-2">
                  {{ $t("form_candidate_Availability") }}
                </div>
                <div class="primary--text body-2">
                  {{ $t(candidate.availability) }}
                </div>
              </div>
              <v-divider></v-divider>
              <v-row class="py-4">
                <!-- <v-col cols="12" md="6" class="py-0">
                <v-list-item two-line style="padding-left:0;">
                  <v-list-item-content>
                    <v-list-item-title class="body"
                      >1ª Lingua</v-list-item-title
                    >
                    <v-list-item-subtitle
                      class="body-2 font-weight-regular"
                      v-if="
                        candidate.languages &&
                          candidate.languages[0] &&
                          candidate.languages[0].name
                      "
                    >
                      {{ candidate.languages[0].name }} -
                      {{ candidate.languages[0].level }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle
                      class="body-2 font-weight-regular"
                      v-else
                    >
                      --- - ---
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col> -->
                <!-- <v-col cols="12" md="6" class="py-0">
                <v-list-item two-line style="padding-left:0;">
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="body"
                      >2ª Lingua</v-list-item-title
                    >
                    <v-list-item-subtitle
                      class="body-2 font-weight-regular"
                      v-if="candidate.languages && candidate.languages[1]"
                    >
                      {{ candidate.languages[1].name }} -
                      {{ candidate.languages[1].level }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle
                      class="body-2 font-weight-regular"
                      v-else
                      >--- - ---</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-col> -->
                <v-col cols="12" md="6" class="py-0">
                  <v-list-item two-line style="padding-left: 0">
                    <v-list-item-content>
                      <v-list-item-title class="body">{{
                        $t("professionalExperience")
                      }}</v-list-item-title>
                      <v-list-item-subtitle
                        class="body-2 font-weight-regular py-2"
                        >{{ days(candidate.professionalExperience) }}
                        {{ $t("year") }}
                        {{
                          days(candidate.professionalExperience) > 1 ? "s" : ""
                        }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <v-list-item two-line style="padding-left: 0">
                    <v-list-item-content>
                      <v-list-item-title class="body">{{
                        this.$t("Interviesalary")
                      }}</v-list-item-title>
                      <v-list-item-subtitle
                        class="body-2 font-weight-regular py-2"
                      >
                        MT{{ candidate.basicSalary }} - MT{{
                          candidate.idealSalary
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="d-flex flex-row px-3 py-8">
                <div
                  class="
                  d-flex
                  flex-row
                  justify-space-between
                  py-4
                  pr-5
                  ajustaEspaco
                "
                >
                  <!-- <v-chip
                    link
                    @click="goCV"
                    label
                    outlined
                    color="primary"
                    class="mx-2"
                    >{{ $t("view") }} CV</v-chip
                  > -->
                  <v-chip
                    label
                    outlined
                    color="primary"
                    @click="showCertificate()"
                  >
                    {{ $t("view_cert") }}
                  </v-chip>
                </div>
              </v-row>
              <v-row class="d-flex flex-row px-3 pb-4" v-if="certificate">
                <div v-if="certificates">
                  <v-row class="d-flex flex-row px-3 pb-4">
                    <div
                      class="d-flex flex-row px-2"
                      v-for="(cert, index) in certificates"
                      :key="index"
                    >
                      <div>
                        <a
                          :href="`${apiUrl}/files/certifications/${cert}`"
                          target="_blank"
                          >{{
                            cert.length > 20
                              ? cert.substring(0, 20) + ".pdf"
                              : cert
                          }}</a
                        >
                      </div>
                      <div>
                        <v-btn
                          x-small
                          icon
                          fab
                          color="secondary"
                          class="pb-1"
                          @click="openConfirmeDialog(index)"
                        >
                          <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </v-row>
                </div>
                <div v-else>
                  <v-row class="d-flex flex-row px-3 pb-4">
                    <div
                      class="d-flex flex-row px-2"
                      v-for="(cert, index) in candidate.certificates"
                      :key="index"
                    >
                      <div>
                        <a
                          :href="`${apiUrl}/files/certifications/${cert}`"
                          target="_blank"
                          >{{
                            cert.length > 20
                              ? cert.substring(0, 20) + ".pdf"
                              : cert
                          }}</a
                        >
                      </div>
                      <div>
                        <v-btn
                          x-small
                          icon
                          fab
                          color="secondary"
                          class="pb-1"
                          @click="openConfirmeDialog(index)"
                        >
                          <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </v-row>
                </div>
                <v-spacer></v-spacer>
                <!-- <div>
                <v-btn
                  x-small
                  fab
                  outlined
                  color="primary"
                  class="mr-4"
                  @click="dialogCertificate = true"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </div> -->
              </v-row>
              <v-divider></v-divider>
              <v-row class="py-4">
                <v-col cols="12" md="6" class="py-0">
                  <v-list-item two-line style="padding-left: 0">
                    <v-list-item-content>
                      <v-list-item-title class="body black--text"
                        >E-mail</v-list-item-title
                      >
                      <v-list-item-subtitle
                        class="body-2 font-weight-regular"
                        >{{ candidate.email }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <v-list-item two-line style="padding-left: 0">
                    <v-list-item-content>
                      <v-list-item-title class="body black--text">{{
                        $t("Contacts")
                      }}</v-list-item-title>
                      <v-list-item-subtitle
                        class="body-2 font-weight-regular"
                        >{{ candidate.telephone }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <v-list-item two-line style="padding-left: 0">
                    <v-list-item-content>
                      <v-list-item-title class="body black--text">{{
                        $t("form_candidate_birth")
                      }}</v-list-item-title>
                      <v-list-item-subtitle
                        class="body-2 font-weight-regular"
                        >{{
                          dateFormat(candidate.dateBirth)
                        }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <v-list-item two-line style="padding-left: 0">
                    <v-list-item-content>
                      <v-list-item-title class="body black--text">{{
                        $t("form_client_adress")
                      }}</v-list-item-title>
                      <v-list-item-subtitle
                        class="body-2 font-weight-regular"
                        >{{ candidate.address }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="12" md="12" class="pb-0 mb-0 my-3 black--text">{{
                  $t("form_candidate_ref")
                }}</v-col>
                <v-col
                  cols="12"
                  md="6"
                  class="py-0"
                  v-for="reference in candidate.references"
                  :key="reference.fullName"
                >
                  <v-list-item two-line style="padding-left: 0">
                    <v-list-item-content>
                      <v-list-item-subtitle class="body black--text">{{
                        reference.fullName
                      }}</v-list-item-subtitle>
                      <v-list-item-subtitle
                        class="body-2 font-weight-regular my-1"
                        >{{ reference.office }}</v-list-item-subtitle
                      >
                      <v-list-item-subtitle
                        class="body-2 font-weight-regular my-3"
                        ><v-icon small>mdi-phone</v-icon>
                        {{ reference.telephone }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </div>
            <VacanciesDetails
              v-if="selectedTab === 'vacancies'"
              :candidate_id="currentUser.candidate.id"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12" md="3">
      <div class="py-0" style="margin-top: -3%">
        <p class="text-left text-uppercase">
          {{ $t("others") }}
        </p>
        <v-divider class="my-6"></v-divider>
      </div>
      <v-card>
        <v-row>
          <v-col cols="12" md="12">
            <v-list-item>
              <v-list-item-content>
                <v-col cols="12" md="6">
                  <div class="text-center">
                    <!-- <pre>{{candidate.id}}</pre> -->
                    <div class="text-h4">
                      {{ views === null ? "0" : views }}
                    </div>

                    <p class="caption primary--text">
                      {{ $t("number_views") }}
                    </p>

                    <!-- <pre>
                      {{currentUser.candidate.id}}
                    </pre> -->
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <div class="text-center">
                    <div class="text-h4">0</div>
                    <p class="caption primary--text">
                      {{ $t("reactProfile") }}
                    </p>
                  </div>
                </v-col>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <v-dialog v-model="dialogCertificate" max-width="800">
      <!-- eslint-disable-next-line vue/valid-v-on -->
      <v-card justify="center" class="pb-10">
        <!-- <v-row justify="center"> -->
        <!-- </v-row> -->
        <vue-dropzone
          id="dropzone"
          :options="dropzoneOptions"
          ref="dropzone"
          @vdropzone-complete="startImport"
          @vdropzone-drop="startLoading"
          @vdropzone-file-added="startLoading"
        ></vue-dropzone>
        <!-- <v-checkbox class="pl-12" v-model="checkbox1" label="Enviar email para os candidatos completarem o cadastro."></v-checkbox> -->
      </v-card>
    </v-dialog>

    <confirm-dialog
      :dialog="confirm"
      :id="removeId"
      :question="$t('remove_doc_quest')"
      @yes="removeCertificate()"
      @no="confirm = false"
    />
    <error-dialog
      :dialog="showError"
      :message="error"
      @close="showError = false"
    />
    <progress-dialog :processing="isLoading" />
    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="showSuccess = false"
    />
    <ShareCVDialog />
  </v-row>
</template>

<script>
import { API_URL } from "@/api";
import {
  REMOVE_CERTIFICATE_MUTATION,
  UPLOAD_CENTIFICATE_MUTATION,
} from "./../graphql/Mutation";
import { mapActions, mapGetters } from "vuex";
import { formatError } from "@/utils";
import yearsMixins from "@/mixins/years";
import moment from "moment";
import ConfirmDialog from "./../../../components/ConfirmDialog.vue";
import ErrorDialog from "./../../../components/ErrorDialog.vue";
import ProgressDialog from "./../../../components/ProgressDialog.vue";
import SuccessDialog from "./../../../components/SuccessDialog.vue";
import VacanciesDetails from "./tabs/VacanciesDetails.vue";
import { ADD_DIALOG } from "@/mixins/dialog";
import vueDropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { CANDIDATE_VIEWS } from "./../graphql/Query";
import { UPDATED_VIEWS, NEW_CERTIFICATE } from "./../graphql/subscription";
// import { CLIENT } from "../../../../../api";
import ShareCVDialog from "./ShareCVDialog.vue";
export default {
  name: "ProfileDetails",
  components: {
    vueDropzone,
    ConfirmDialog,
    ErrorDialog,
    ProgressDialog,
    SuccessDialog,
    ShareCVDialog,
    VacanciesDetails,
  },
  mixins: [ADD_DIALOG, yearsMixins],
  data: () => ({
    apiUrl: API_URL,
    certificate: false,
    certificates: [],
    dialogCertificate: false,
    dropzoneOptions: {
      url: "https://httpbin.org/post",
      thumbnailWidth: 200,
      addRemoveLinks: true,
      dictDefaultMessage:
        '<div class="v-card v-sheet theme--light grey lighten-3" style="height: 250px;">' +
        '<div class="text-center"><p class="text-center py-10">Solte ou arraste nesta esta área para importar ou clique no botão abaixo</p>' +
        '<div style="margin-top:-5%"><i aria-hidden="true" class="v-icon notranslate mb-5 v-icon--right mdi mdi-cloud-upload theme--light primary--text"></i></div>' +
        '<button type="button" class="v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default">' +
        '<span class="v-btn__content">importar ficheiro</span></button></div><p class="text-center pt-2 overline">.pdf .doc .docx .rtf</p></div>',
    },

    views: 0,
    count: 0,
    selectedTab: "about",
  }),
  apollo: {
    views: {
      query: CANDIDATE_VIEWS,
      variables() {
        return {
          candidateId: this.currentUser.candidate.id
            ? this.currentUser.candidate.id
            : 1,
        };
      },
      fetchPolicy: "no-cache",
    },
    $subscribe: {
      message: {
        query: UPDATED_VIEWS,
        result({ data }) {
          console.log("candidate", data.candidateCandidacyView.views);
          if (
            data.candidateCandidacyView.candidate.id ===
            this.currentUser.candidate.id
          ) {
            this.views = data.candidateCandidacyView.views;
            this.count = data.candidateCandidacyView.views;
          }
        },
      },
      certificate: {
        query: NEW_CERTIFICATE,
        result({ data }) {
          if (data.candidateCertificate.certificates) {
            if (data.candidateCertificate.id === this.candidate.id) {
              this.certificates = data.candidateCertificate.certificates;
            }
          }
        },
      },
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    candidate() {
      return this.currentUser ? this.currentUser.candidate : {};
    },
    tabs() {
      return [
        {
          key: "about",
          text: this.$t("about"),
        },
        {
          key: "vacancies",
          text: this.$t("vacancies"),
        },
      ];
    },
  },
  mounted() {
    this.certificateList();
  },
  created() {
    this.certificateList();
  },
  methods: {
    ...mapActions({
      updateCandidateState: "auth/updateCandidate",
    }),
    openConfirmeDialog(id) {
      this.removeId = id;
      this.confirm = true;
    },
    dateFormat(date) {
      return moment(date)
        .lang("pt")
        .format("LL");
    },

    openShareDialog() {
      // eslint-disable-next-line no-undef
      Fire.$emit("shareCandidateCV", this.candidate);
    },
    goCV() {
      ///candidate/candidate/cv
      this.$router.push({ path: `/candidate/candidate/cv` });
      // this.openShareDialog();
      // window.open(CLIENT + `localhost:8080_pdf_${this.candidate.id}` , "_blank");
    },
    async removeCertificate() {
      try {
        this.confirm = false;
        this.isLoading = true;
        await this.$apollo.mutate({
          mutation: REMOVE_CERTIFICATE_MUTATION,
          variables: { index: this.removeId },
        });
        let candidate = this.currentUser.candidate;
        candidate.certificates.splice(this.removeId, 1);
        this.updateCandidateState(candidate);
        this.success = this.$t("removeCertificate");
        this.showSuccess = true;
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
    showCertificate() {
      this.certificate = !this.certificate;
    },
    async certificateList() {
      this.certificates = await this.candidate.certificates;
    },
    async startImport() {
      try {
        const certificate = await this.$refs.dropzone.getAcceptedFiles()[0];
        const { data } = await this.$apollo.mutate({
          mutation: UPLOAD_CENTIFICATE_MUTATION,
          variables: {
            certificate,
          },
        });
        let candidate = this.currentUser.candidate;
        candidate.certificates.push(data.uploadCertificate);
        this.updateCandidateState(candidate);
        this.success = this.$t("addCertificate");
        this.showSuccess = true;
        this.dialogCertificate = false;
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
    startLoading() {
      this.isLoading = true;
    },
    uploadCertificate() {
      try {
        this.isLoading = true;
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style scoped>
.subtile {
  font-size: 1.2rem !important;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
.small-perfil:hover {
  border: 1px solid #f7a400;
}
.show-btns:not(.on-hover) {
  color: #f7a400 !important;
}
.ajustaEspaco {
  margin-top: -3%;
  margin-bottom: -2.5%;
}
.ajustaEspaco2 {
  margin-top: -4%;
  margin-bottom: -0.5%;
}
@media screen and (min-width: 300px) and (max-width: 1366px) {
  .ajustaEspaco {
    margin-top: -4%;
    margin-bottom: -3.5%;
  }
  .ajustaEspaco2 {
    margin-top: -6%;
    margin-bottom: -1.7%;
  }
}
</style>
